@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-date-picker__wrapper {
  border: none !important;
}

em {
  color: red;
  font-weight: 400;
}

.p-organizationchart-line-top {
  border-top-width: 1px;
}

.p-organizationchart-line-top.p-organizationchart-line-right {
  border-left-width: 1px;
}

.p-organizationchart-line-top.p-organizationchart-line-left {
  border-right-width: 1px;
}

/* .p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #e5e7eb;
  border-color: #e5e7eb;
} */

.p-icon.p-node-toggler-icon{
  left: 4px;
  top: 5px;
}